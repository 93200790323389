import {getAxiosInstance} from "../../../../services/providers/api";
import {PainelVendasFilterProps} from "../../../../global/dataTransferObjects/transacoesTempoReal";
import axios from "axios";
import config from '../../../../config/index';


export default class PainelDePagamentosService {

  getPainelDePagamentos(filter: any) {
    if(filter.acquirer == -1){
      delete filter.acquirer
    }
    return getAxiosInstance().post('/transacoes/payments', filter)
  }


  salvarPagamentos(liveTransactions: any) {
    return getAxiosInstance().post('/transacoes/update-payments',liveTransactions)
  }

  async getFileDownload(filter?: PainelVendasFilterProps) {
    const result = await getAxiosInstance().post('/transacoes/payments-xls', filter, {responseType: 'blob'});
    return result.data;
  }

  async getPagamentos (filter: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    const formattedFilter = {
      SaleInitDate: filter.initialDate,
      SaleFinishDate: filter.finalDate,
      TipoPagamento: filter.tipoPagamento,
      BusinessName: filter.businessName,
      Acquirer: filter.acquirer,
      Paid: filter.paid,
      AnticipationIncluded: filter.anticipationIncluded
    };

    if (formattedFilter.Acquirer == -1) delete formattedFilter.Acquirer;

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/filtro`, {
      ...formattedFilter
    },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      }
    )
  }

  async updatePagamentosExternos (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return await axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza`, data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosCredito (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza-credito`, data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosDebitoPix (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/api/painel-pagamentos/atualiza-debito`,data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosPixAsync (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza-pix`, data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

}
