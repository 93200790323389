import {FormValues} from "../types";
import {getAxiosInstance} from "../../../services/providers/api";
import {fi} from "date-fns/locale";
import axios from "axios";
import config from "../../../config";

export default class TerminaisPhoebusService {
  getAllTerminals = async (
    page: number,
    pageSize: number,
    filter?: FormValues
  ) => {

    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    const paged = true;
    const terminal = filter?.terminal !== undefined && filter?.terminal.length > 0 ? filter?.terminal : null;
    const merchantId = parsedSelectedCompany ? parsedSelectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const status = filter?.status !== -1 ? filter?.status : null;
    const serialNumber = filter?.serialNumber !== undefined && filter?.serialNumber.length > 0 ? filter?.serialNumber : null;
    const modelName = filter?.modelName !== -1 ? filter?.modelName : null;
    const tefTerminal = filter?.tefTerminal !== undefined && filter?.tefTerminal.length > 0 ? filter?.tefTerminal : null;
    const isAssociated = filter?.isAssociated !== -1 ? filter?.isAssociated : null;

    try {
      const response = await getAxiosInstance().post('/terminal/get-terminal', {
        offSet: page,
        pageSize: pageSize,
        paged: paged,
        terminal: terminal,
        merchantId: merchantId,
        status: status,
        serialNumber: serialNumber,
        modelName: modelName,
        tefTerminal: tefTerminal,
        isAssociated: isAssociated,
      });

      return response.data.response;
    } catch(error) {
      return {success: false, error: error};
    }
  }

  getTerminalDownload = async (
    page: number,
    pageSize: number,
    filter?: FormValues
  ) => {
    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    const paged = true;
    const terminal = filter?.terminal !== undefined && filter?.terminal.length > 0 ? filter?.terminal : null;
    const merchantId = parsedSelectedCompany ? parsedSelectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const status = filter?.status !== -1 ? filter?.status : null;
    const serialNumber = filter?.serialNumber !== undefined && filter?.serialNumber.length > 0 ? filter?.serialNumber : null;
    const modelName = filter?.modelName !== -1 ? filter?.modelName : null;
    const tefTerminal = filter?.tefTerminal !== undefined && filter?.tefTerminal.length > 0 ? filter?.tefTerminal : null;
    const isAssociated = filter?.isAssociated !== -1 ? filter?.isAssociated : null;

    try {
      const response = await getAxiosInstance().post('/terminal/get-terminal-xls', {
        offSet: page,
        pageSize: pageSize,
        paged: false,
        terminal: terminal,
        merchantId: merchantId,
        status: status,
        serialNumber: serialNumber,
        modelName: modelName,
        tefTerminal: tefTerminal,
        isAssociated: isAssociated,
      },
        { responseType: 'blob'}
      );

      return response.data;
    } catch(error) {
      return {success: false, error: error};
    }
  }

  forceTerminalJob = async ()=> {
    const page = 1;
    const pageSize = 100;

    return getAxiosInstance().get('/phoebus/terminal', {
      params: {
        page: page,
        pageSize: pageSize
      }
    });
  }
}
