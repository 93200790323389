import React, {useEffect, useState} from 'react';
import { withFormik } from 'formik';
import {toast} from "react-toastify";
import { Row } from 'antd';
import Select from 'antd/lib/select';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
  ValueCard,
  FilterRow,
  FilterContainer, ButtonSalvar, Col,
} from './styles';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../../../global/dataTransferObjects/transacoesTempoReal';
import { FormProps, IFormProps } from '../../types';
import { formatDate } from '../../../../../utils/formatDate';
import useGetMe from '../../../../../hooks/useCurrentUser'
import PainelDePagamentosService from "../../service/PainelDePagamentosService";
const painelDePagamentosService = new PainelDePagamentosService();
const FormFilter: React.FC<FormProps> = (props: FormProps) => {
  const user = useGetMe();
  const { handleSubmit, liveTransactions, externalLiveTransactions,loading, setLoading, filter } = props;
  const [bandeira, setBandeira] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState('0');
  const [businessName, setBusinessName] = useState('');
  const [statusPagamento, setStatusPagamento] = useState<string>("");
  const [acquirer, setAcquirer] = useState(-1);
  const [paid, setPaid] = useState<boolean | null>(null);
  const [anticipationIncluded, setAnticipationIncluded] = useState<boolean | null>(null);


  useEffect(() => {
    props.setFieldValue('initialDate', new Date());
    props.setFieldValue('finalDate', new Date());
  }, []);

  const formatMoney = (value:number) => {
    const formattedValue = (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const getCurrentDateAsString = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const getValorVendaTotal = () => {
    let valor = 0;
    if (liveTransactions != null && liveTransactions.length > 0) {
      valor =liveTransactions.map(value => value.valorVenda).reduce((acc, value) => acc + value, 0);
    }
    if (externalLiveTransactions !== null && externalLiveTransactions.length > 0) {
      valor = valor + externalLiveTransactions.map(value => value.valorVenda).reduce((acc, value) => acc + value, 0);
    }
    const valorFormatado = formatMoney(valor);
    return valorFormatado;
  }

  const getTicket = () => {
    let ticket = 0;
    if (liveTransactions != null && liveTransactions.length > 0) {
      ticket = (liveTransactions.map(value => value.value).reduce((acc, value) => acc + value, 0))/liveTransactions.length;
    }
    const valorFormatado = formatMoney(ticket);
    return valorFormatado;
  }

  const getValorLiquidoTotal = () => {
    let valor = 0;
    if (liveTransactions != null && liveTransactions.length > 0) {
      valor =liveTransactions.map(value => value.valorLiquido).reduce((acc, value) => acc + value, 0);
    }
    if (externalLiveTransactions !== null && externalLiveTransactions.length > 0) {
      valor = valor + externalLiveTransactions.map(value => value.valorLiquido).reduce((acc, value) => acc + value, 0);
    }
    const valorFormatado = formatMoney(valor);
    return valorFormatado;
  }

  const getValoresAReceber = () => {
    let valor = 0;
    if (liveTransactions != null && liveTransactions.length > 0) {
      valor = liveTransactions.filter(value => value.paymentStatus === 'Pending').map(value => value.liquidValue).reduce((acc, value) => acc + value, 0);
    }
    const valorFormatado = formatMoney(valor);
    return valorFormatado;
  }

  const getValoresPagos = () => {
    let valor = 0;
    if (liveTransactions != null && liveTransactions.length > 0) {
      valor = liveTransactions.filter(value => value.paymentStatus === 'Payed').map(value => value.liquidValue).reduce((acc, value) => acc + value, 0);
    }
    const valorFormatado = formatMoney(valor);
    return valorFormatado;
  }

  const handleChangeBandeira = (value: string) => {
    setBandeira(value);
    props.setFieldValue('bandeira', value);
  };
  const handleChangeTipoPagamento = (value: string) => {
    setTipoPagamento(value);
    props.setFieldValue('tipoPagamento', value);
  };

  const handleChangeStatus = (value: string) => {
    setStatusPagamento(value);
    props.setFieldValue('statusPagamento', value);
  };

  const handleBusinessNameChange = (event: any) => {
    setBusinessName(event.target.value);
    props.setFieldValue('businessName', event.target.value);
  };

  const handleChangeAcquirer = (value: number) => {
    setAcquirer(Number(value));
    props.setFieldValue('acquirer', Number(value));
  };

  const handleChangePaid = (value: boolean | null) => {
    setPaid(value);
    props.setFieldValue('paid', value);
  }

  const handleChangeAnticipation = (value: boolean | null) => {
    setAnticipationIncluded(value);
    props.setFieldValue('anticipationIncluded', value);
  }

  const atualizaPagamentos = async () => {
    setLoading(true);

    let pagamentosDTO: any[] = [];

    liveTransactions.forEach(value => {
      let pagamento = {
        businessId: value.businessId,
        creditoPago: value.creditoPago,
        debitoPago: value.debitoPago,
        pixPago : value.pixPago
      }
      pagamentosDTO.push(pagamento);
    })

    try {
      await painelDePagamentosService.salvarPagamentos({
        filtros: filter,
        pagamentos: pagamentosDTO
      });

      await painelDePagamentosService.updatePagamentosExternos(externalLiveTransactions);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div style={{display:'flex',flexDirection:'row'}}>
        <ValueCard>
          <div>
            <p style={{fontWeight: 'bolder'}}>Valor Líquido: </p>
            <p>{getValorLiquidoTotal()}</p>
          </div>
          <div>
            <p style={{fontWeight: 'bolder'}}>Valor das Vendas: </p>
            <p>{getValorVendaTotal()}</p>
          </div>

        </ValueCard>
        <div>
            <ButtonSalvar onClick={() => {
              atualizaPagamentos();
            }} style={{marginBottom: '25px', width: '150px', alignSelf: 'start'}}>Salvar</ButtonSalvar>

        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtrar por:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col>
                  <DatePickerField
                    name="initialDate"
                    placeholder="Data inicial"
                  />
                </Col>
          <Col>
                  <DatePickerField
                    name="finalDate"
                    placeholder="Data final"
                  />
                </Col>
          <Col>
              <Select
                defaultValue="0"
                style={{width: '100%'}}
                onChange={handleChangeTipoPagamento}
                options={[
                  {
                    value: '0',
                    label: 'Tipo pagamento',
                  },
                  {
                    value: '1',
                    label: 'Debito',
                  },
                  {
                    value: '2',
                    label: 'Credito',
                  },
                  {
                    value: '3',
                    label: 'Pix',
                  },
                ]}
              />
            </Col>
          <Col>
              <input placeholder="Empresa" onChange={handleBusinessNameChange}/>
            </Col>
          <Col>
              <Select
                defaultValue={-1}
                style={{width: '100%'}}
                onChange={handleChangeAcquirer}
                options={[
                  {
                    value: -1,
                    label: 'Adquirente',
                  },
                  {
                    value: 3,
                    label: 'Adiq',

                  },
                  {
                    value: 10,
                    label: 'Rede',
                  },
                  {
                    value: 99,
                    label: 'Valori',
                  },
                ]}
              />
            </Col>
          <Col>
            <Select
              defaultValue={false}
              style={{width: '100%'}}
              onChange={handleChangePaid}
              options={[
                {
                  value: null,
                  label: 'Status Pagamento',
                },
                {
                  value: true,
                  label: 'Pago',
                },
                {
                  value: false,
                  label: 'Pendente',
                },
              ]}
            />
          </Col>
          <Col>
            <Select
              defaultValue={null}
              style={{width: '100%'}}
              onChange={handleChangeAnticipation}
              options={[
                {
                  value: null,
                  label: 'Antecipação',
                },
                {
                  value: true,
                  label: 'Antecipado',
                },
                {
                  value: false,
                  label: 'Não Antecipado',
                },
              ]}
            />
          </Col>
          <Col>
            <Button style={{background: '#fbb651'}} htmlType="submit">
              Consultar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFormProps, PainelVendasFilterProps>({
  mapPropsToValues: (): PainelVendasFilterProps => ({
    initialDate: '',
    finalDate: '',
    tipoPagamento: '0',
    businessName: '',
    acquirer: -1,
    paid: false,
    anticipationIncluded: null
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    if (values.initialDate == null || values.finalDate == null) {
      toast.error('Preencha as datas corretamente!')
      return;
    }

    const initialDateValue = values.initialDate;
    const finalDateValue = values.finalDate;
    const dateI = formatDate(initialDateValue);
    const dateF = formatDate(finalDateValue);

    const test = {
      ...values,
      initialDate: dateI,
      finalDate: dateF,
    }
    props.onFormSubmit(test);
  },
})(FormFilter);
